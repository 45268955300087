import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
//import responsive from './slider-resonsive'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ProjectSlider1({ data }) {
  console.log('projectslider', data);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(4, data?.length),
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(4, data?.length),
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: Math.min(2, data?.length),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1, data?.length),
        },
      },
    ],
  };
  return (
    <>
      <Slider
        {...settings}
        className="img-carousel-dots-nav btn-style-1 icon-2"
      >
        {data &&
          data.map((item, id) => (
            <Link to="/products"><div className="item">
              {console.log('item is', item)}
              <div className="dlab-box project-bx">
                <div className="dlab-media radius-sm dlab-img-overlay1 dlab-img-effect zoom">

                  <img
                    src={`https://hr.sweetitech.co.uk/abc/${item.images[0].url}`}
                    alt=""
                  />

                </div>

              </div>
              <div style={{ backgroundColor: "#ffffff", padding: 20 }}><div className="dlab-info">
                <h5 className="dlab-title">
                  {item.name}
                </h5>
                <div className="dlab-post-text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${item?.details.slice(0, 180)}...`,
                    }}
                  />
                </div>

                <div className="dlab-post-readmore">
                  <div
                    className="site-button btnhover14"
                  >
                    SEE MORE
                  </div>
                </div>
              </div></div>

            </div></Link>

          ))}
      </Slider>
    </>
  );
}
export default ProjectSlider1;
