import React, { Component } from 'react';
import Modal from 'react-modal';

class FloatCalendar extends Component {
    state = {
        showModal: false,
    };

    // Function to toggle the modal
    toggleModal = () => {
        this.setState((prevState) => ({ showModal: !prevState.showModal }));
    };

    render() {
        const { pdfUrl } = this.props; // Pass the PDF URL as a prop

        return (
            <>
                {/* Floating calendar button */}
                <button
                    className={`float-calendar ${this.props.className}`}
                    type="button"
                    onClick={this.toggleModal}
                    style={{
                        position: 'fixed',
                        bottom: '80px',
                        right: '10px',
                        backgroundColor: '#f7941d',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '50%',
                        width: '60px',
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 4px 6px rgba(247, 212, 29, 0.8)',
                        cursor: 'pointer',
                    }}
                >
                    <i className="fa fa-calendar"></i>
                </button>

                {/* Modal to display the PDF */}
                <Modal
                    isOpen={this.state.showModal}
                    onRequestClose={this.toggleModal}
                    contentLabel="PDF Viewer"
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 100050,
                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '60%',
                            height: 'auto',
                        },
                    }}
                >
                    <div className="float-calendar-modal-header">
                        <h2>Julfikar Steel Calender 2025</h2>
                        <button onClick={this.toggleModal} className="float-calendar-close-button">X</button>
                    </div>
                    <iframe
                        src={`${pdfUrl}#zoom=0`}
                        title="Julfikar Steel Calender 2025"
                        className="float-calendar-pdf-viewer"
                    />
                    <div className="float-calendar-modal-footer">
                        <a href={pdfUrl} download className="site-button btnhover14">
                            Download PDF
                        </a>
                    </div>
                </Modal>
            </>
        );
    }
}

export default FloatCalendar;
