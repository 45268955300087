/* eslint-disable no-dupe-args */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import '../../css/slider-animations.css';
import '../../css/homeslider.css';

const content = [
  {
    title: 'Vulputate Mollis Ultricies Fermentum Parturient',
    description:
      'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.',
    button: 'Read More',
    image: 'https://i.imgur.com/ZXBtVw7.jpg',
    user: 'Luan Gjokaj',
    userProfile: 'https://i.imgur.com/JSW6mEk.png',
  },
  {
    title: 'Tortor Dapibus Commodo Aenean Quam',
    description:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui.',
    button: 'Discover',
    image: 'https://i.imgur.com/DCdBXcq.jpg',
    user: 'Erich Behrens',
    userProfile: 'https://i.imgur.com/0Clfnu7.png',
  },
  {
    title: 'Phasellus volutpat metus',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula.',
    button: 'Buy now',
    image: 'https://i.imgur.com/DvmN8Hx.jpg',
    user: 'Bruno Vizovskyy',
    userProfile: 'https://i.imgur.com/4KeKvtH.png',
  },
];

function HomeSlider1({
  data,
  contentWrapperCls,
  btnText,
  hideBtn,
  show2Btn,
  btn2Text,
  contentCls,
  btnCls,
}) {
  console.log('sliderContent', data);

  return (
    <>
      {/* autoplay={5000} */}
      <div className="parent-slider-wrapper">
        <div className="slider-hero-video"
        >
          {/* {data.map((item, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                background: `url(' https://hr.sweetitech.co.uk/abc/${item.image[0].url}') no-repeat center `,
              }}
            >
              {console.log('image is', item.image)}
              <div className={`inner ${contentCls}`}>
                {item.title && (
                  <h1
                    style={{
                      fontWeight: 'normal',
                    }}
                  >
                    {item.title}
                  </h1>
                )}
                {item.description && <p>{item.description}</p>}

                {hideBtn ? (
                  ''
                ) : show2Btn ? (
                  <>
                    <button className={btnCls}>{btnText}</button>
                    <button className={btnCls}>{btn2Text}</button>
                  </>
                ) : (
                  <button className={btnCls}>{btnText}</button>
                )}
              </div>
            </div>
          ))} */}

          <div

            className="slider-content"
            style={{
              background: 'none', // Remove background image styling
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%', // Ensure the height matches the container's height
            }}
          >
            {/* Display the video element */}
            <video
              src={require(`../../video/JULFIKERSTEELCGV12.mp4`)} // Use local video path
              autoPlay
              loop
              muted
              playsInline // Ensures autoplay works on mobile devices
              className='hero-video'
            />

            <div className={`inner ${contentCls}`}>



              {hideBtn ? (
                ''
              ) : show2Btn ? (
                <>
                  <button className={btnCls}>{btnText}</button>
                  <button className={btnCls}>{btn2Text}</button>
                </>
              ) : (
                <button className={btnCls}>{btnText}</button>
              )}
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
export default HomeSlider1;
