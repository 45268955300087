import React, { Component, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer';
import DatePicker from 'react-datepicker';
import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
import { useEffect } from 'react';
import { deleteOrder, getOrder } from '../server';
import moment from 'moment';
import Swal from 'sweetalert2';

const bg = require('../../images/banner/Rod-pattern.jpg');

const numOfElements = 6;
function ShopWishlist() {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [status, setStatus] = React.useState('requested');
  const [orders, setOrders] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [imagesToshow, setImagesToshow] = useState([]);

  useEffect(() => {
    const fromDate = moment(startDate).format('YYYY-MM-DD');
    const toDate = moment(endDate).format('YYYY-MM-DD');
    getOrder(fromDate, toDate, status)
      .then((res) => {
        console.log('resAboutUs', res.data);
        setOrders(res.data);
        setTotalPages(Math.ceil(res.data.length / numOfElements));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [startDate, endDate, status]);
  const paginatedData = useMemo(() => {
    return orders?.slice(
      (currentPage - 1) * numOfElements,
      currentPage * numOfElements
    );
  }, [orders, currentPage]);

  const handelDelete = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteOrder(id)
          .then((res) => {
            Swal.fire({
              title: 'Success!',
              text: 'Order Deleted Successfully',
              icon: 'success',
              confirmButtonText: 'Ok',
            });
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <>
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: 'url(' + bg + ')' }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">Order List</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>Order List</li>
                  <Link to="/order-now">
                    <li>Order Now {'>'} </li>
                  </Link>
                  <Link to="/">
                    <li>Home{'>'} </li>
                  </Link>
                  <Link
                    onClick={() => {
                      localStorage.removeItem('userId');
                      localStorage.removeItem('access_token');
                      window.location.href = '/login-page';
                    }}
                  >
                    <li>Log Out</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            paddingTop: '20px',
          }}
        >
          <div className="form-group" style={{ paddingRight: '10px' }}>
            <label>From Date</label>
            <DatePicker
              className="form-control"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div className="form-group" style={{ paddingRight: '10px' }}>
            <label>To Date</label>
            <DatePicker
              className="form-control"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
          <div className="form-group">
            <label>Status</label>
            <select
              className="form-control"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="requested">Requested</option>
              <option value="approved">Approved</option>
            </select>
          </div>
        </div>

        <>
          <div className="table-responsive" style={{ overflowX: 'scroll' }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sl No.</th>
                  <th>Customer Name</th>
                  <th>Address</th>
                  <th>Delivery Information</th>
                  <th>Sale Person</th>
                  <th>QTN ton</th>
                  <th>Rate(Per Ton) </th>
                  <th>8 TMT</th>
                  <th>10 TMT</th>
                  <th>12 TMT</th>
                  <th>16 TMT</th>
                  <th>20 TMT</th>
                  <th>25 TMT</th>
                  <th>Load & Truck Fare</th>
                  <th>Total Price</th>
                  <th>Paid Amount</th>
                  <th>Payment After Delivery</th>
                  <th>Credit</th>
                  <th>Bank Details</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData?.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        {currentPage === 1
                          ? index + 1
                          : 6 * (currentPage - 1) + (index + 1)}
                      </td>
                      <td>{item?.accountName}</td>
                      <td>{item?.address}</td>
                      <td>{item?.deliveryInformation}</td>
                      <td>{item?.reference}</td>
                      <td>
                        {Number(
                          item?.dwrs[0]?.quantity ? item?.dwrs[0]?.quantity : 0
                        ) +
                          Number(
                            item?.dwrs[1]?.quantity
                              ? item?.dwrs[1]?.quantity
                              : 0
                          ) +
                          Number(
                            item?.dwrs[2]?.quantity
                              ? item?.dwrs[2]?.quantity
                              : 0
                          ) +
                          Number(
                            item?.dwrs[3]?.quantity
                              ? item?.dwrs[3]?.quantity
                              : 0
                          ) +
                          Number(
                            item?.dwrs[4]?.quantity
                              ? item?.dwrs[4]?.quantity
                              : 0
                          ) +
                          Number(
                            item?.dwrs[5]?.quantity
                              ? item?.dwrs[5]?.quantity
                              : 0
                          )}
                      </td>
                      <td>{item.exMillRate}</td>

                      <td>
                        {item?.dwrs
                          .filter((dwr) => dwr?.title === '8 MM')
                          .map((dwrsItem, index) => (
                            <div key={index}>
                              {dwrsItem.quantity}
                              {dwrsItem.dwr !== undefined
                                ? ` (DWR:${dwrsItem.dwr})`
                                : ''}
                            </div>
                          ))}
                      </td>
                      <td>
                        {item?.dwrs
                          .filter((dwr) => dwr?.title === '10 MM')
                          .map((dwrsItem, index) => (
                            <div key={index}>
                              {dwrsItem.quantity}
                              {dwrsItem.dwr !== undefined
                                ? ` (DWR:${dwrsItem.dwr})`
                                : ''}
                            </div>
                          ))}
                      </td>
                      <td>
                        {item?.dwrs
                          .filter((dwr) => dwr?.title === '12 MM')
                          .map((dwrsItem, index) => (
                            <div key={index}>
                              {dwrsItem.quantity}
                              {dwrsItem.dwr !== undefined
                                ? ` (DWR:${dwrsItem.dwr})`
                                : ''}
                            </div>
                          ))}
                      </td>
                      <td>
                        {item?.dwrs
                          .filter((dwr) => dwr?.title === '16 MM')
                          .map((dwrsItem, index) => (
                            <div key={index}>
                              {dwrsItem.quantity}
                              {dwrsItem.dwr !== undefined
                                ? ` (DWR:${dwrsItem.dwr})`
                                : ''}
                            </div>
                          ))}
                      </td>
                      <td>
                        {item?.dwrs
                          .filter((dwr) => dwr?.title === '20 MM')
                          .map((dwrsItem, index) => (
                            <div key={index}>
                              {dwrsItem.quantity}
                              {dwrsItem.dwr !== undefined
                                ? ` (DWR:${dwrsItem.dwr})`
                                : ''}
                            </div>
                          ))}
                      </td>
                      <td>
                        {item?.dwrs
                          .filter((dwr) => dwr?.title === '25 MM')
                          .map((dwrsItem, index) => (
                            <div key={index}>
                              {dwrsItem.quantity}
                              {dwrsItem.dwr !== undefined
                                ? ` (DWR:${dwrsItem.dwr})`
                                : ''}
                            </div>
                          ))}
                      </td>
                      <td>{Number(item?.loadingAndTruckFare)}</td>
                      <td>
                        {item?.dwrs.reduce(
                          (total, item) => total + Number(item?.price),
                          0
                        ) + Number(item?.loadingAndTruckFare)}
                      </td>

                      <td>
                        {item?.banks.reduce(
                          (acc, item) =>
                            acc +
                            item.amount
                              .split(',')
                              .reduce(
                                (subTotal, num) => subTotal + parseFloat(num),
                                0
                              ),
                          0
                        )}
                      </td>
                      <td>
                        {item?.paymentAfterDelivery === 'true' ? 'Yes' : 'No'}
                      </td>
                      <td>{item?.credit === 'true' ? 'Yes' : 'No'}</td>
                      <td style={{ display: 'flex' }}>
                        {item?.banks.map((bank, index) => {
                          return (
                            <div>
                              <p style={{ marginLeft: '10px' }}>
                                {index === item?.banks.length - 1
                                  ? `${bank?.account}`
                                  : `${bank?.account},`}
                              </p>
                            </div>
                          );
                        })}
                      </td>
                      <td>
                        {status === 'requested' ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Link
                              to={'/orderEdit/' + item.id}
                              className="btn btn-info"
                            >
                              Edit
                            </Link>
                            <button
                              className="btn btn-danger"
                              style={{
                                marginRight: '10px',
                                marginLeft: '10px',
                              }}
                              onClick={() => {
                                handelDelete(item?.id);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div>N/A</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>

        <div className="pagination-bx clearfix  col-md-12 text-center">
          <ul className="pagination" style={{ justifyContent: 'center' }}>
            <li className="previous">
              <Link
                to={'#'}
                onClick={() => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                <i className="ti-arrow-left"></i> Prev
              </Link>
            </li>
            {[...Array(totalPages)].map((item, index) => (
              <li className={currentPage === index + 1 ? 'active' : ''}>
                <Link to={'#'} onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </Link>
              </li>
            ))}

            <li className="next">
              <Link to={'#'} onClick={() => setCurrentPage(currentPage + 1)}>
                Next <i className="ti-arrow-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default ShopWishlist;
