import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import Header from '../layout/header2';
import Footer from '../layout/footer';
import BlogSlider1 from '../element/blogSlider1';
import { useState } from 'react';
import { useEffect } from 'react';
import { getBanners } from '../server';
import { Link } from 'react-router-dom';
const bg = require('../../images/background/bg5.jpg');

function Contact1() {
  const [news, setNews] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    getBanners({ type: 'contact us', subType: 'contact us' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        google={props.google}
        defaultZoom={17}
        defaultCenter={props.center}
      >
        <Marker position={props.center} />
      </GoogleMap>
    ))
  );
  return (
    <>
      <Header />
      {banner && (
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{
            backgroundImage: `url(' https://hr.sweetitech.co.uk/abc/${banner.image.url}')`,
          }}
        />
      )}

      <div className="section-full p-t50 p-b20 bg-primary text-white overlay-primary-dark footer-info-bar">
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Contact Us</h1>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
              <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                <div className="icon-content">
                  <h5 className="dlab-tilte">
                    <span className="icon-sm">
                      <i className="ti-location-pin"></i>
                    </span>
                    Corporate Office
                  </h5>
                  <p className="op7">
                    LANDVIEW-12th Floor.  28, Gulshan North C/A, Gulshan 2, Dhaka-1212
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
              <div
                className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1"
                style={{
                  height: '100%',
                }}
              >
                <div className="icon-content">
                  <h5 className="dlab-tilte">
                    <span className="icon-sm">
                      <i className="ti-alarm-clock"></i>
                    </span>
                    Head Office
                  </h5>
                  <p className="op7">
                    B-342, Enaetnagar, Godnail, Siddhirganj Narayanganj-1432,
                    Bangladesh
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
              <div
                className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1"
                style={{
                  height: '100%',
                }}
              >
                <div className="icon-content">
                  <h5 className="dlab-tilte">
                    <span className="icon-sm">
                      <i className="ti-email"></i>
                    </span>
                    E-mail
                  </h5>
                  <p className="m-b0 op7">mail@julfikarsteel.com</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
              <div
                className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1"
                style={{
                  height: '100%',
                }}
              >
                <div className="icon-content">
                  <h5 className="dlab-tilte">
                    <span className="icon-sm">
                      <i className="ti-mobile"></i>
                    </span>
                    Phone Numbers
                  </h5>
                  <p className="m-b0 op7">Mobile : 01409998242</p>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>

      <div className="page-content bg-white">
        <div className="">
          <div className="row">
            <div className="col-md-6">
              <MyMapComponent
                isMarkerShown
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB78hfZtM0bfvCzv3BqPoqI5wT6JkqJDtw&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                center={{ lat: 23.79477077225461, lng: 90.41484006521829 }} // Second map center
              />
            </div>
            <div className="col-md-6">
              <MyMapComponent
                isMarkerShown
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB78hfZtM0bfvCzv3BqPoqI5wT6JkqJDtw&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                center={{ lat: 23.647792867263625, lng: 90.51119006862885 }} // First map center
              />
            </div>

          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Contact1;
